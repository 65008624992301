<template>
  <ProductSlide
    :product-pack="item.storageUnit.productPack"
    :actual-amount="displayAmount"
    :expected-amount="item.plannedAmount * item.storageUnit.productPack.quantityInMinMeasurementUnits"
    :amount-badge-color="productSlideAmountBadgeColor"
    title-clickable
    @delete="emit('delete')"
    @title-click="emit('product-click', item.storageUnit.productPack.product)"
    @amount-click="handleAmountClick"
  >
    <template
      v-if="item.targetPack"
      #before-amount
    >
      <QChip
        dense
        color="purple-4"
        text-color="white"
        square
        clickable
        icon="mdi-arrow-right"
      >
        {{ expectedAmountInTargetUnits }} {{ item.targetPack.measurementUnit.shortName }}
      </QChip>
    </template>
    <template
      v-if="indicesWithProduct.length > 1"
      #under-amount
    >
      <CarouselIndicators
        :count="indicesWithProduct.length"
        :active="indicesWithProduct.indexOf(index)"
        :limit="5"
        :indicator-size="7"
      />
    </template>
  </ProductSlide>
</template>

<script setup lang="ts">

import CarouselIndicators from '@/components/Mobile/CarouselIndicators.vue';
import ProductSlide from '@/components/Mobile/ProductSlide.vue';
import type { Product } from '@/graphql/types';
import getPacksRatio from '@/helpers/getPacksRatio';
import type { TransferCarouselItem } from '@/types/transfer';
import { computed } from 'vue';
import useTransferProcessStore from '@/stores/transferProcess';

const store = useTransferProcessStore();

const props = defineProps<{
  item: TransferCarouselItem;
  index: number;
  displayAmount: number | string;
}>();

const emit = defineEmits<{
  (e: 'delete'): void;
  (e: 'product-click', product: Product): void;
  (e: 'update:slide', slide: number): void;
}>();

const productSlideAmountBadgeColor = computed((): string | undefined => {
  if (props.item.takenAmount === props.item.plannedAmount) {
    return 'green-3';
  }

  if (props.item.takenAmount > 0) {
    return 'yellow-3';
  }

  return undefined;
});

const indicesWithProduct = computed(() => store.carouselItems
  .map((item, index) => [item, index] as const)
  .filter(([item]) => item.storageUnit.productPack.product.id
    === props.item.storageUnit.productPack.product.id)
  .map(([, index]) => index));

function handleAmountClick() {
  const index = (indicesWithProduct.value.indexOf(props.index) + 1) % indicesWithProduct.value.length;

  emit('update:slide', (indicesWithProduct.value)[index]);
}

const expectedAmountInTargetUnits = computed(() => {
  if (!props.item.targetPack) {
    return null;
  }
  const ratio = getPacksRatio(props.item.storageUnit.productPack, props.item.targetPack);

  if (!ratio) {
    return null;
  }

  const [sourceQuantity, targetQuantity] = ratio;

  return props.item.plannedAmount * targetQuantity / sourceQuantity;
});

</script>

