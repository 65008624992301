<template>
  <FilterableTable
    ref="itemsTable"
    :fields="itemsHeaders"
    :rows="task.items"
    :item-meets-search-string="null"
    :storage-prefix="'transferTaskCard.' + props.task.id"
    show-line-numbers
    shrink
    no-loading-columns
  >
    <template #body-cell-sku="column">
      <BodyCellLink
        :column="column"
        :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: column.row.productPack.product.id } }"
        variant="link"
      />
    </template>
    <template #body-cell-repacking="column">
      <QTd :props="column">
        <template v-if="column.value">
          {{ column.value[0] }}
          <QIcon name="mdi-arrow-right" />
          {{ column.value[1] }}
        </template>
      </QTd>
    </template>
  </FilterableTable>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import type {
  ProductPack,
  TransferTask,
  TransferTaskItem,
} from '@/graphql/types';
import getPacksRatio from '@/helpers/getPacksRatio';
import type { TableColumn } from '@/types/reports';
import { useI18n } from 'vue-i18n';
import {  ref } from 'vue';
import { unitAmount } from '@/helpers/itemAmount';
import FilterableTable from '@/components/FilterableTable.vue';
import ROUTES from '@/router/routeNames';
import * as R from 'ramda';

const { t } = useI18n();

const props = defineProps<{
  task: TransferTask;
}>();

function packAmount(amount: number, productPack: ProductPack) {
  if (!productPack.smallerProductPack) {
    return null;
  }
  const amountInBasUnits = productPack.quantityInMinMeasurementUnits;
  return t('{amount} of {multiplier} ({amountInBaseUnits})', {
    amount:            unitAmount(amount, productPack.measurementUnit),
    multiplier:        unitAmount(amountInBasUnits, productPack.minMeasurementUnit),
    amountInBaseUnits: unitAmount(amount * amountInBasUnits, productPack.minMeasurementUnit),
  });
}

const itemsHeaders: TableColumn<TransferTaskItem>[] = [
  {
    label: t('SKU'),
    name:  'sku',
    field: i => i.productPack.product.sku,
    align: 'left',
  },
  {
    label: t('Name'),
    name:  'productPack.product.name',
    field: i => i.productPack.product.name,
    align: 'left',
  },
  {
    label: t('Origin Cell/Container'),
    name:  'storageFrom',
    field: i => i.storageFrom.name,
    align: 'left',
  },
  {
    label: t('Target Cell/Container'),
    name:  'storageTo',
    field: i => i.storageTo?.name,
    align: 'left',
  },
  {
    label: t('Amount'),
    name:  'itemAmount',
    field: i => unitAmount(i.plannedAmount, i.productPack.measurementUnit),
    title: i => packAmount(i.plannedAmount, i.productPack),
  },
  {
    label: t('Transferred'),
    name:  'transferredAmount',
    field: i => unitAmount(i.transferredAmount, i.productPack.measurementUnit),
    title: i => packAmount(i.transferredAmount, i.productPack),
  },
  {
    label: t('Not Transferred'),
    name: 'notTransferredAmount',
    field: i => unitAmount(i.plannedAmount - i.transferredAmount, i.productPack.measurementUnit),
    title: i => packAmount(i.plannedAmount - i.transferredAmount, i.productPack),
  },
  {
    label: t('Available Amount'),
    name: 'availableAmount',
    field: i => unitAmount(R.min(i.availableAmount, i.plannedAmount - i.transferredAmount), i.productPack.measurementUnit),
    title: i => packAmount(R.min(i.availableAmount, i.plannedAmount - i.transferredAmount), i.productPack),
  },
  {
    label: t('Repacking'),
    name: 'repacking',
    field: i => {
      if (!i.targetProductPack) {
        return null;
      }
      const ratio = getPacksRatio(i.productPack, i.targetProductPack)!;
      return [
        `${i.plannedAmount} ${i.productPack.measurementUnit.shortName}`,
        `${i.plannedAmount * ratio[1] / ratio[0]} ${i.targetProductPack.measurementUnit.shortName}`,
      ];
    },
  },
];

const itemsTable = ref();

</script>

<i18n lang="yaml">
ru:
  Available Amount: Доступный остаток
  "{amount} of {multiplier} ({amountInBaseUnits})": >
    {amount} из {multiplier} ({amountInBaseUnits})
en:
  Available Amount: Available Amount
  "{amount} of {multiplier} ({amountInBaseUnits})": >
    {amount} of {multiplier} ({amountInBaseUnits})
</i18n>
